<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Proyectos - Consumo de horas</h4>
            <div class="small text-muted">Detalle del consumo de horas para el proyecto</div>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            
            <b-badge variant="dark" v-if="this.projectSelected.customer">
              {{this.projectSelected.customer.name}}
            </b-badge>
            {{this.projectSelected.name}}  

            <FullCalendar defaultView="dayGridMonth" 
                          :plugins="calendarPlugins"                           
                          :locale="calendarOptions.locale"
                          :weekends="true" 
                          :events="calendarOptions.event"
                          :selectable="true" 
                          @dateClick="dateClick "/>
          </b-card>                            
        </b-col>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            <b-row class="mb-3">
              <b-col md="6">

                <b-badge variant="dark" v-if="this.projectSelected.customer">
                  {{this.projectSelected.customer.name}}
                </b-badge>
                {{this.projectSelected.name}}  

                <h2>{{moment(this.dateSelected).format('DD')}} de 
                    {{moment(this.dateSelected).format('MMMM')}} de 
                    {{moment(this.dateSelected).format('YYYY')}}</h2>
              </b-col>              
            </b-row>
            <b-row>
              <b-col md="12">                        
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        v-if="table.items.length">
                    
                    <template v-slot:cell(employee_id)="data">                      
                      <b>{{ data.item.employee.id }} - {{ data.item.employee.name }}</b>                      
                    </template>
                  
                    <template v-slot:cell(description)="data">
                      <div v-if="data.item.description.length>50" :title="data.item.description">
                        {{ data.item.description.substring(0,50) }} ...
                      </div>
                      <div v-else>
                        {{ data.item.description }}
                      </div>
                    </template>

                    <template v-slot:cell(hours)="data">
                      <b>{{ data.item.hours }} hs</b>
                    </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                
              </b-col>
            </b-row>            
            <b-row class="mt-3" align-h="center" v-if="table.items.length">
              <b-col md="5" center>
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>                      
                      <b-th colspan="2" class="text-center">TOTALES</b-th>
                    </b-tr>
                  </b-thead>      
                  <b-tfoot>
                    <b-tr>
                      <b-td colspan="7" variant="secondary" class="text-right">
                        Total de hs en día: <b>{{this.totalProjects.toFixed(2)}} Hs</b>
                      </b-td>
                    </b-tr>
                  </b-tfoot>                             
                </b-table-simple>
              </b-col>              
            </b-row>
          </b-card>
          
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>
            </b-row>
          </b-card>          
        </b-col>
      </b-row>              
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'   

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'    
  import esLocale from '@fullcalendar/core/locales/es';
  import interactionPlugin from '@fullcalendar/interaction';

  export default {
    components: {                
      FullCalendar
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.PROYECTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailHoursProjects',
          elements: {}
        }, 
        calendarPlugins: [ dayGridPlugin, interactionPlugin ],        
        calendarOptions: {
          locale: esLocale,
          event: []
        },
        table : {
          items: [],
          fields: [            
            {key: 'employee_id', label: 'Empleado', sortable: true},            
            {key: 'description', label: 'Detalle'},            
            {key: 'hours', label: 'Horas', class:"text-center", sortable: true},
            {key: 'f_action', label:''},
          ],        
        }, 
        dateSelected: moment(),       
        projectSelectedID: 0,
        projectSelected: {}
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted () {      
      this.projectSelectedID = this.$route.params.projectID  

      moment.locale('es');
      this.load()      
    },      
    computed: {
      totalProjects: function() {
        var total = 0
        this.table.items.forEach(element => {
          if(element.projects_id) {
            total = total + parseFloat(element.hours)
          }          
        }) 
        return total       
      },
      isEmployee(){
        if(Helper.getEmployee()) {
          if(Helper.getEmployee().permission_admin_projects) {
            return false  
          } else {
            return true
          }                    
        } else {
          return false
        }
      },           
    },
    methods: {
      validation() {
        var employee = Helper.getEmployee()        
        if(employee) {
          if(this.projectSelected.employee_id != employee.id) {
            this.$router.push({ name: 'Error403' })
          }
        }
      },
      dateClick(date) { 
        this.dateSelected = date.dateStr                 
        this.getHours()        
      },
      load(){
        this.loadCalendar()
        this.getHours()
        this.loadProjectSelected()
      },
      loadCalendar() {   
        let loader = this.$loading.show();

        this.calendarOptions.event = []

        var data = {          
          project_id: this.projectSelectedID         
        }
        var result = serviceAPI.obtenerHorasByProject(data)

        result.then((response) => {                    
          var data = response.data

          data.projects.forEach(element => {
            var event = {}

            event = {
              title: 'Horas: ' + element.hours + ' hs',
              start: element.date,
              end: element.date,
              color: "#bae6c9",
            }            
            this.calendarOptions.event.push(event)            
          });           

          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });                     
      },
      getHours() {
        let loader = this.$loading.show();
        var data = {
          project_id: this.projectSelectedID,
          date: this.dateSelected 
        }              
        var result = serviceAPI.obtenerHorasByDiaByProyecto(data)

        result.then((response) => {                    
          var data = response.data
          
          data.forEach(element => {
            this.table.items.push(element)
          });
          
          this.table.items = data   
                              
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      }, 
      loadProjectSelected(){
        let loader = this.$loading.show();
        var result = serviceAPI.mostrarProyectos(this.projectSelectedID)

        result.then((response) => {                            
          var data = response.data                            
          this.projectSelected = data        
          
          // VALIDACION DE VISUALIZACION
          this.validation()
          // FIN VALIDACION

          loader.hide()
        }) 
        .catch(error => {       
          loader.hide()   
          this.$awn.alert(ErrorToken.valid(error));
        }) 
      }     
    }
  }
</script>
<style>
  .project-detail-hours-badge {
    font-size: 13px;
  }
</style>